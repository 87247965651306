import React from 'react';
import type { FC } from 'react';

import { SmartCardProvider } from '@atlaskit/link-provider';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import {
	MacroExperienceFailure,
	MacroExperienceSuccess,
	EDITOR,
	RENDERER,
} from '@confluence/macro-tracker';

import type { LinkCardsParameters } from '../linkCardsTypes';
import { CardSizes } from '../linkCardsTypes';
import { linkCardsExtensionType } from '../linkCardsExtensionType';

import {
	EmptyCard,
	ExtraSmallCard,
	HeroCard,
	LargeCard,
	MediumCard,
	SmallCard,
} from './CardComponents';
import { CardsGrid } from './CardsGrid';
import { cardsDimensions } from './GridCardWrapper';
import { useCardsCombinedExperienceState } from './useCardsCombinedExperienceState';
import { useValidateRequiredParameters } from './useValidateRequiredParameters';
import { useCardsMediaConfig } from './useCardsMediaConfig';

type LinkCardsContentProps = {
	parameters: LinkCardsParameters;
	isInViewMode?: boolean;
	contentId: string;
	experienceName: string;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
};

const CARD_SIZE_MAP = {
	[CardSizes.EXTRA_SMALL]: ExtraSmallCard,
	[CardSizes.SMALL]: SmallCard,
	[CardSizes.MEDIUM]: MediumCard,
	[CardSizes.LARGE]: LargeCard,
	[CardSizes.HERO]: HeroCard,
};

const getCardComponent = (cardSize: CardSizes) => {
	return CARD_SIZE_MAP[cardSize] ?? MediumCard;
};

export const LinkCardsContent: FC<LinkCardsContentProps> = ({
	parameters,
	isInViewMode,
	contentId,
	experienceName,
	createAnalyticsEvent,
}) => {
	const validatedParameters = useValidateRequiredParameters(parameters);
	const { size, cards, alignment, isAvatarShown, isPublishDateShown } = validatedParameters;

	const { areAllCardsLoaded, cardFailureError, onCardSucceeded, onCardFailed } =
		useCardsCombinedExperienceState(cards);

	const cardHeight = cardsDimensions[size].height;
	const CardComponent = getCardComponent(size);

	const { mediaConfig } = useCardsMediaConfig({
		isInViewMode,
		contentId,
	});

	return (
		<SmartCardProvider>
			<CardsGrid numberOfCards={cards.length} cardsSize={size} cardsAlignment={alignment}>
				{cards.map((card) => {
					// TODO CTE-2947 deconstruct card params and pass only what is necessary for each card size/type
					return !!card.link ? (
						<CardComponent
							key={card.cardId}
							cardHeight={cardHeight}
							isAvatarShown={isAvatarShown}
							isPublishDateShown={isPublishDateShown}
							isInViewMode={!!isInViewMode}
							onCardSucceeded={onCardSucceeded}
							onCardFailed={onCardFailed}
							mediaConfig={mediaConfig}
							extensionType={linkCardsExtensionType}
							analyticsSource="cardsExtensionConfig"
							createAnalyticsEvent={createAnalyticsEvent}
							{...card}
						/>
					) : (
						<EmptyCard
							key={card.cardId}
							size={size}
							cardHeight={cardHeight}
							isInViewMode={!!isInViewMode}
							onCardSucceeded={onCardSucceeded}
							onCardFailed={onCardFailed}
							mediaConfig={mediaConfig}
							{...card}
						/>
					);
				})}
			</CardsGrid>
			{areAllCardsLoaded && (
				<MacroExperienceSuccess
					name={experienceName}
					mode={isInViewMode ? RENDERER : EDITOR}
					contentId={contentId}
				/>
			)}
			{!!cardFailureError && (
				<MacroExperienceFailure
					name={experienceName}
					contentId={contentId}
					mode={isInViewMode ? RENDERER : EDITOR}
					error={cardFailureError || new Error(`Link cards failed to render`)}
					attributes={{}}
					source="LinkCardsContent"
				/>
			)}
		</SmartCardProvider>
	);
};
